import logo from './logo.svg';
import './App.css';
import React from 'react';

class CatFact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    fetch("https://catfact.ninja/fact")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <p>
          {items.fact}
        </p>
      );
    }
  }
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          New web app coming soon!
        </p>
        <a
          className="App-link"
          href="https://web.aido.no"
          target="_blank"
          rel="noopener noreferrer"
        >
          In the meantime: Use the current web app
        </a>
        <p style={{fontSize: 11}}>
          Also. Here is a cat fact (courtesy of catfact.ninja): <CatFact />
        </p>
      </header>
    </div>
  );
}

export default App;
